import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const LineChartEnergy = () => {
  const startDate = new Date('2023-01-01');
  const endDate = new Date('2023-12-31');

  const dates = [];
  let currentDate = startDate;

  while (currentDate <= endDate) {
    dates.push(currentDate.getTime());
    currentDate.setDate(currentDate.getDate() + 6);
    
  }

  // Example data for the curve (modify this with your actual data)
  const curveData = [
    { x: new Date('2023-01-01').getTime(), y: 200 },
    { x: new Date('2023-01-10').getTime(), y: 250},
    { x: new Date('2023-01-15').getTime(), y: 180 },
    { x: new Date('2023-01-25').getTime(), y: 204 },
    { x: new Date('2023-01-30').getTime(), y: 250 },
    { x: new Date('2023-02-01').getTime(), y: 247 },
    { x: new Date('2023-02-10').getTime(), y: 251 },
    { x: new Date('2023-02-15').getTime(), y: 145 },
    { x: new Date('2023-02-25').getTime(), y: 230 },
    { x: new Date('2023-02-27').getTime(), y: 278 },
    { x: new Date('2023-03-01').getTime(), y: 240 },
    { x: new Date('2023-03-10').getTime(), y: 250 },
    { x: new Date('2023-03-15').getTime(), y: 180 },
    { x: new Date('2023-03-25').getTime(), y: 240 },
    { x: new Date('2023-03-30').getTime(), y: 220 },
    { x: new Date('2023-04-01').getTime(), y: 210 },
    { x: new Date('2023-04-10').getTime(), y: 125 },
    { x: new Date('2023-04-15').getTime(), y: 210 },
    { x: new Date('2023-04-25').getTime(), y: 270 },
    { x: new Date('2023-04-30').getTime(), y: 220 },
    { x: new Date('2023-05-01').getTime(), y: 250 },
    { x: new Date('2023-05-10').getTime(), y: 281 },
    { x: new Date('2023-05-15').getTime(), y: 288 },
    { x: new Date('2023-05-25').getTime(), y: 301 },
    { x: new Date('2023-05-30').getTime(), y: 258 },
    { x: new Date('2023-06-01').getTime(), y: 247 },
    { x: new Date('2023-06-10').getTime(), y: 250 },
    { x: new Date('2023-06-15').getTime(), y: 225 },
    { x: new Date('2023-06-25').getTime(), y: 260 },
    { x: new Date('2023-06-27').getTime(), y: 278 },
    { x: new Date('2023-07-01').getTime(), y: 200 },
    { x: new Date('2023-07-10').getTime(), y: 250 },
    { x: new Date('2023-07-15').getTime(), y: 210 },
    { x: new Date('2023-07-25').getTime(), y: 270 },
    { x: new Date('2023-07-30').getTime(), y: 220 },
    { x: new Date('2023-08-01').getTime(), y: 210 },
    { x: new Date('2023-08-10').getTime(), y: 250 },
    { x: new Date('2023-08-15').getTime(), y: 230 },
    { x: new Date('2023-08-25').getTime(), y: 278 },
    { x: new Date('2023-08-30').getTime(), y: 220 },
    { x: new Date('2023-09-01').getTime(), y: 256 },
    { x: new Date('2023-09-10').getTime(), y: 250 },
    { x: new Date('2023-09-15').getTime(), y: 229 },
    { x: new Date('2023-09-25').getTime(), y: 276 },
    { x: new Date('2023-09-30').getTime(), y: 225 },
    { x: new Date('2023-10-01').getTime(), y: 200 },
    { x: new Date('2023-10-10').getTime(), y: 250 },
    { x: new Date('2023-10-15').getTime(), y: 230 },
    { x: new Date('2023-10-25').getTime(), y: 264 },
    { x: new Date('2023-10-30').getTime(), y: 220 },
    { x: new Date('2023-11-01').getTime(), y: 247 },
    { x: new Date('2023-11-10').getTime(), y: 250 },
    { x: new Date('2023-11-15').getTime(), y: 205 },
    { x: new Date('2023-11-25').getTime(), y: 210 },
    { x: new Date('2023-11-27').getTime(), y: 278 },
    { x: new Date('2023-12-01').getTime(), y: 200 },
    { x: new Date('2023-12-10').getTime(), y: 250 },
    { x: new Date('2023-12-15').getTime(), y: 220 },
    { x: new Date('2023-12-25').getTime(), y: 270 },
    { x: new Date('2023-12-30').getTime(), y: 220 },

    // Add more points as needed
  ];

  const [chartState, setChartState] = useState({
    series: [
      {
        name: 'XYZ MOTORS',
        data: dates.map((date, index) => ({
          x: date,
          y: curveData[index] ? curveData[index].y : null,
        })),
      },
    ],
    options: {
      chart: {
        type: 'area',
        stacked: false,
        height: 350,
        zoom: {
          type: 'x',
          enabled: true,
          autoScaleYaxis: true,
        },
        toolbar: {
          autoSelected: 'zoom',
        },
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 0,
      },
      title: {
        text: 'Energy Consumption',
        align: 'left',
      },
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          inverseColors: false,
          opacityFrom: 0.5,
          opacityTo: 0,
          stops: [0, 90, 100],
        },
      },
      yaxis: {
        labels: {
          formatter: function (val) {
            return (val ).toFixed(0);
          },
        },
        title: {
          text: 'Energy Consumption (KwH)',
        },
      },
      xaxis: {
        type: 'datetime',
      },
      tooltip: {
        shared: false,
        y: {
          formatter: function (val) {
            return (val ).toFixed(0) + ' KWh';
          },
        },
      },
    },
  });

  return (
    <div id="chart">
      <ReactApexChart options={chartState.options} series={chartState.series} type="area" height={350} />
    </div>
  );
};

export default LineChartEnergy;