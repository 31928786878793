import { useState } from "react";
import { ProSidebar, Menu, MenuItem,SubMenu  } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import MachineIcon from '@mui/icons-material/PrecisionManufacturing';
import EnergySavingsLeafIcon from '@mui/icons-material/EnergySavingsLeaf';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import Co2Icon from '@mui/icons-material/Co2';
import WarehouseRoundedIcon from '@mui/icons-material/WarehouseRounded';
import picture from '../../assets/images/EcoFactory.jpeg'
const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
      </MenuItem>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");

  return (
    <Box className="sidebar-container"
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {!isCollapsed && (
            <Box mb="25px">
              <Box display="flex" justifyContent="center" alignItems="center">
                <img
                  alt="profile-user"
                  width="100px"
                  height="100px"
                  src={picture}
                  style={{ cursor: "pointer", borderRadius: "50%" }}
                />
              </Box>
              <Box textAlign="center">
                <Typography
                  variant="h2"
                  color={colors.grey[100]}
                  fontWeight="bold"
                  sx={{ m: "10px 0 0 0" }}
                >
                  EcoFactoriz
                </Typography>
              </Box>
            </Box>
          )}

          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
         
            <Item
              title="Dashboard"
              to="/home/dash"
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
              
            <Item
              title="Supervision"
              to="/home/supervision1"
              icon={<RemoveRedEyeIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            
            
              <SubMenu  title="Analysis"               
              to="/analysis"
              icon={<AnalyticsIcon />}
              selected={selected}
              setSelected={setSelected}>
                            <Item
              title="Machine N°14"
              to="/home/analysis"
              icon={<MachineIcon />}
              selected={selected}
              setSelected={setSelected}          
            />
                <Item
              title="Machine N°24"
              to="/home/analysis"
              icon={<MachineIcon />}
              selected={selected}
              setSelected={setSelected}          
            />
                <Item
              title="Machine N°23"
              to="/home/analysis"
              icon={<MachineIcon />}
              selected={selected}
              setSelected={setSelected}          
            />
              </SubMenu>

             <SubMenu  title="Resources Management"               
              to="/home/source_management "
              icon={<EnergySavingsLeafIcon />}
              selected={selected}
              setSelected={setSelected}>
                            
                <Item
              title="Raw Material"
              to="/home/row_material"
              icon={< WarehouseRoundedIcon/>}
              selected={selected}
              setSelected={setSelected}          
            />
                <Item
              title="Stop Machine"
              to="/home/stop_machine"
              icon={<PrecisionManufacturingIcon />}
              selected={selected}
              setSelected={setSelected}          
            />
              </SubMenu>
              <Item
              title="Energy Management"
              to="/home/energy_management"
              icon={<EnergySavingsLeafIcon/>}
              selected={selected}
              setSelected={setSelected}
            /> 
             <Item
              title="Carbon Footprint"
              to="/home/carbon_footprint"
              icon={<Co2Icon/>}
              selected={selected}
              setSelected={setSelected}
            /> 
           
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
