import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const ColumnChartMaterial = () => {
  const [chartState, setChartState] = useState({
    series: [{
      name: 'Availability',
      data: [77,71,75,72,70,68,65,70,75,78,79,78,79,73,77,75,77,75,78,74,78,79,77,78,45,44,43,40,45,43,45,46,45,44,77,78,79,76,77,74,75]
    }],
    options: {
      annotations: {
        points: [{
          x: 'Bananas',
          seriesIndex: 0,
          label: {
            borderColor: '#775DD0',
            offsetY: 0,
            style: {
              color: '#fff',
              background: 'E5E7E9',
            },
            text: 'Availability ',
          }
        }]
      },
      chart: {
        height: 350,
        type: 'bar', 
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          columnWidth: '50%',
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: 2
      },
      grid: {
        row: {
          colors: ['#fff', '#f2f2f2']
        }
      },
      xaxis: {
        labels: {
          rotate: -45
        },
        categories: ['1', '2', '3', '4', '5', '6','7', '8', '9', '10', '11', '12', '13','14','15', '16', '17', '18', '19', '20','21', '22', '21', '22', '23', '24', '25','26','27','28','29','30','31','32','33','34'
        ,'35','36','37','38','39'],
        tickPlacement: 'on'
      },
      yaxis: {
        title: {
          text: 'Row Material Used',
        },
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'light',
          type: "horizontal",
          shadeIntensity: 0.25,
          gradientToColors: undefined,
          inverseColors: true,
          opacityFrom: 0.85,
          opacityTo: 0.85,
          stops: [50, 0, 100]
        },
      }
    },
  });

  return (
    <div id="chart">
      <ReactApexChart options={chartState.options} series={chartState.series} type="bar" height={350} />
    </div>
  );
};

export default ColumnChartMaterial;