import React from 'react';
import { Box, Typography, useTheme } from "@mui/material";
import { Row } from "react-bootstrap";
import Header from "../../components/Header";
import Scheduler from "../../components/scheduler/Scheduler";
const StopMachine = () => {
  return (
    <div className="container-fluid p-20" >
         <Row className="justify-content-center">
        <Header title="Stop Machines Of The Factory" />
      </Row>
      <Row className="mt-4 pr-12 pl-12">
      <div className="container-fluid mt-30">
          
             
               <Scheduler />
             
            
          </div>
        
    </Row>
    
    

  
    </div>
    
  );
};

export default StopMachine;