import React from 'react';
import DemarrageImage from './demarrage.png';

const Demarrage = ({ width = 50 }) => {
  return (
    <img
      src={DemarrageImage}
      alt="Demarrage"
      style={{ width: `${width}%`, height: 'auto' }}
    />
  );
};

export default Demarrage;
