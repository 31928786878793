import Auth from "./scenes/authentification/autentif";
import Home from "./scenes/home/home";
import { useState } from "react";
import { Routes, Route } from "react-router-dom";
import {  useMode } from "./theme";


function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);

  return (
   
            <Routes>
              <Route path="/home/*" element={<Home />} />
              <Route path="/" element={<Auth />} />
            </Routes>
          
  );
}

export default App;