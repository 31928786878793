import React from 'react';
import RealTimeGraph from '../../components/RealTimeGraph';
import ColumnNegative from '../../components/ColumnNegative';
import LineChartEnergy from '../../components/LineChartEnergy';
import '../../assets/css/sourceanalytix.css';
import { Container, Row, Col, Card } from "react-bootstrap";
const Sourceanalytix = () => {
   
      const containerStyle = {
        backgroundColor: '#f2f2f2', // Set the background color to gray
        padding: '20px', // Add padding for spacing
      };
    return ( 
      <Container fluid className="p-20" style={containerStyle}>
      <Row className="mt-4 pr-12 pl-12">
        <Col>
        <Card border="light" className="shadow-sm" style={{ backgroundColor: '#E5E7E9' }}>
            <Card.Body>
              <RealTimeGraph />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mt-4 pr-12 pl-12">
        <Col>
        <Card border="light" className="shadow-sm" style={{ backgroundColor: '#E5E7E9' }}>
            <Card.Body>
              <ColumnNegative/>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mt-4 pr-12 pl-12">
        <Col>
        <Card border="light" className="shadow-sm" style={{ backgroundColor: '#E5E7E9' }}>
            <Card.Body>
              <LineChartEnergy/>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
      
    );
  };
  
  export default Sourceanalytix;