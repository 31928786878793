
import Header from "../../components/Header";
import ColumnChartMaterial from  '../../components/ColumnChartMaterial';
import '../../assets/css/dashboard.css';
import React, { useState } from 'react';
import { Row } from "react-bootstrap";
import { useEffect } from "react";

const RowMaterial = () => {
  
  return (
    
    <div className="container-fluid p-20" >
         <Row className="justify-content-center">
        <Header title="Row Material" />
      </Row>
     <Row>           
    <div class="col-sm-12">
    <style>
            {`
              h5 {
                color: #000000 ; /* Light blue color */
              }
            `}
           </style>
           <h5>Row Material Used</h5>
      
      <ColumnChartMaterial className="ml-auto"/>
        </div>
                </Row>
   </div>
    
  );
};

export default RowMaterial;