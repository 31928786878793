import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import LineChart from "../../components/LineChart";
import LineChartweek from "../../components/LineChartweek";
import LineChartyear from "../../components/LineChartyear";
import BarChart from "../../components/BarChart";
import PieChart from "../../components/PieChart";
import React, { useState } from 'react';

const Analysis = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [timeParam, setTimeParam] = useState('choices');
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedWeek, setSelectedWeek] = useState(null);
  const [selectedMachine, setSelectedMachine] = useState(null);

  const handleTimeParamChange = (event) => {
    setTimeParam(event.target.value);
    setSelectedYear(null);
    setSelectedMonth(null);
    setSelectedWeek(null);
  };

  const handleYearChange = (event) => {
    setSelectedYear(parseInt(event.target.value));
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };
  
  const handleWeekChange = (event) => {
    setSelectedWeek(parseInt(event.target.value));
  };
  
  const handleMachineChange = (event) => {
    setSelectedMachine(parseInt(event.target.value));
  };
  
  const machineOptions = Array.from({ length: 12 }, (_, index) => index + 1);

  return (
<Box m="20px">
  {/* MENU */}
  <Box
    mt={2}
    display="flex"
    alignItems="center"
    justifyContent="flex-end"  
    position="absolute"       
    top="20px"                
    right="20px"              
  >
    <select value={selectedMachine} onChange={handleMachineChange}>
      <option value={null}>choices</option>
      {machineOptions.map((machine) => (
        <option value={machine} key={machine}>
          {machine}
        </option>
      ))}
    </select>
  </Box>
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={`Machine ${selectedMachine != null ? selectedMachine : ""}`} />
        
      </Box>
   
  </Box>
  );
};

export default Analysis;