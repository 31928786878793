import React from 'react';
import ReactApexChart from 'react-apexcharts';

class TotalCarbonEmissionsC extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [12, 15, 20, 5, 8],
      options: {
        chart: {
          width: 380,
          type: 'donut',
          dropShadow: {
            enabled: true,
            color: '#111',
            top: -1,
            left: 3,
            blur: 3,
            opacity: 0.2,
          },
        },
        stroke: {
          width: 0,
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                total: {
                  label: "Tons of CO2 e",
                  showAlways: true,
                  show: true,
                  formatter: function (w) {
                    const total = w.globals.seriesTotals.reduce((a, b) => a + b, 0);
                    return total ;
                  }

                },
              },
            },
          },
        },
        labels: ['Zone A', 'Zone B', 'Zone C', 'Zone D', 'Zone E'],
        dataLabels: {
          dropShadow: {
            blur: 3,
            opacity: 0.8,
          },
        },
        fill: {
          type: 'pattern',
          opacity: 1,
          pattern: {
            enabled: true,
            style: ['verticalLines', 'squares', 'horizontalLines', 'circles', 'slantedLines'],
          },
        },
        states: {
          hover: {
            filter: 'none',
          },
        },
        theme: {
          palette: 'palette2',
        },
        title: {
          text: 'Indirect Emissions from Waste ',
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: 'bottom',
              },
            },
          },
        ],
      },
    };
  }

  render() {
    return (
      <div id="chart" style={{ padding: '20px' }}>
        <ReactApexChart options={this.state.options} series={this.state.series} type="donut" width={380} />
      </div>
    );
  }
}

export default TotalCarbonEmissionsC ;