import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import Scheduler from "../../components/scheduler/Scheduler";
import StatBoxP from "../../components/StatBoxP";
import StatBoxA from "../../components/StatBoxA";
import StatBoxD from "../../components/StatBoxD";
import ErrorIcon from '@mui/icons-material/Error';
import GppGoodIcon from '@mui/icons-material/GppGood';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import ApexChartA from '../../components/RadialBarA';
import ApexChartB from '../../components/RadialBarB';
import ApexChartC from '../../components/RadialBarC';
import DynamicLoadedChart from '../../components/DynamicLoadedChart ';
import ColumnChart from '../../components/ColumnChart';
import '../../assets/css/dashboard.css';
import React, { useState } from 'react';
import { Row } from "react-bootstrap";
import { useEffect } from "react";

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const borderColor = theme.palette.mode === "dark" ? "#1e1e1e" : "#e6ebf1";

  const [timeParam, setTimeParam] = useState('choices');
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedWeek, setSelectedWeek] = useState(null);

  const handleTimeParamChange = (event) => {
    setTimeParam(event.target.value);
    setSelectedYear(null);
    setSelectedMonth(null);
    setSelectedWeek(null);
  };

  const handleYearChange = (event) => {
    setSelectedYear(parseInt(event.target.value));

  };

  useEffect(() => {
    setTimeParam("week")

  }, [])

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const handleWeekChange = (event) => {
    setSelectedWeek(parseInt(event.target.value));
  };



  return (

    <div className="container-fluid p-20" >
      {/* HEADER */}
      <Row className="row1">
        <Header title="DASHBOARD" />
      </Row>


      {/* GRID & CHARTS */}
      <Row>
        <div className="col-md-4">
          <Box
            gridColumn="span 3"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              border: "1px solid",
              borderColor: borderColor, // Replace with your desired border color
              borderRadius: "10px", // Adjust the value to control the roundness of the border
              // Add any additional styles here, if needed
            }}
          >
            <StatBoxA
              title="3"
              subtitle="Machine Stopped"
              progress="0.06"
              increase="6%"
              icon={
                <ErrorIcon
                  sx={{ color: colors.redAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box>
        </div>
        <div className="col-md-4">
          <Box
            gridColumn="span 3"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              border: "1px solid",
              borderColor: borderColor, // Replace with your desired border color
              borderRadius: "10px", // Adjust the value to control the roundness of the border
              // Add any additional styles here, if needed
            }}
          >          <StatBoxP
              title="25"
              subtitle="Machine in Production"
              progress="0.14"
              increase="80%"
              icon={
                <GppGoodIcon
                  sx={{ color: colors.greenAccent[500], fontSize: "26px" }}
                />
              }
              color={colors.greenAccent[500]}
            />
          </Box>
        </div>
        <div className="col-md-4">
          <Box
            gridColumn="span 3"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              border: "1px solid",
              borderColor: borderColor, // Replace with your desired border color
              borderRadius: "10px", // Adjust the value to control the roundness of the border
              // Add any additional styles here, if needed
            }}
          >          <StatBoxD
              title="7"
              subtitle="Machine in Maintenance"
              progress="0.80"
              increase="14%"
              icon={
                <RocketLaunchIcon
                  sx={{ color: colors.yellowAccent[700], fontSize: "26px" }}
                />
              }
            />
          </Box>
        </div>
      </Row>
      <Row className="mt-4 pr-12 pl-12">
        <div className="col-md-12 ">
          <Box mt="25px" p="0 30px" display="flex" justifyContent="space-between" alignItems="center">
            <div className="col">
              <Typography variant="h5" fontWeight="600" color={colors.grey[100]}>
                {timeParam !== 'choices' && timeParam}
              </Typography>
            </div>
            <select value={timeParam} onChange={handleTimeParamChange}>
              <option value="week" selected>Week</option>
              <option value="month">Month</option>
              <option value="year">Year</option>

            </select>

            {timeParam !== 'year' && timeParam !== 'choices' && (
              <select value={selectedYear} onChange={handleYearChange}>
                <option value={2019}>2019</option>
                <option value={2020}>2020</option>
                <option value={2021}>2021</option>
                <option value={2022}>2022</option>
                <option value={2023}>2023</option>
              </select>
            )}
            {timeParam === 'week' && (
              <select value={selectedMonth} onChange={handleMonthChange}>
                <option value="January">January</option>
                <option value="February">February</option>
                <option value="March">March</option>
                <option value="April">April</option>
                <option value="May">May</option>
                <option value="June">June</option>
                <option value="July">July</option>
                <option value="August">August</option>
                <option value="September">September</option>
                <option value="October">October</option>
                <option value="November">November</option>
                <option value="December">December</option>
              </select>
            )}
            {timeParam === 'week' && (
              <select value={selectedWeek} onChange={handleWeekChange}>
                <option value={1}>Week 1</option>
                <option value={2}>Week 2</option>
                <option value={3}>Week 3</option>
                <option value={4}>Week 4</option>
              </select>
            )}
          </Box>
          <div className="container-fluid mt-30">
            <Box height="auto" marginTop="-20px">
              {/*{timeParam === 'year' && <LineChartyear/>}
          {timeParam === 'month' && <LineChart />}
            {timeParam === 'week'  && (<LineChartweek />  )}*/}
              {timeParam === 'year' && <Scheduler />}
              {timeParam === 'month' && <Scheduler />}
              {timeParam === 'week' && (<Scheduler />)}
            </Box>
          </div>
        </div>

      </Row>
      <Row className="mt-4 pr-12 pl-12" style={{ justifyContent: "unset" }}>
        <div class="col-sm-4" >
          <style>
            {`
              .zonea {
                color: #3498db; /* Light blue color */
              }
            `}
          </style>
          <h1 className="zonea">OEE</h1>
          <h2 className="zonea">Zone A</h2>
          <ApexChartA className="ml-auto" />
        </div>
        <div class="col-sm-4">
          <style>
            {`
              .zoneb {
                color: #ff8000; /* Light blue color */
              }
            `}
          </style>
          <h1 className="zoneb">OEE</h1>
          <h2 className="zoneb">Zone B</h2>
          <ApexChartB className="ml-auto" />
        </div>
        <div class="col-sm-4">
          <style>
            {`
              .zonec {
                color: #77f07f; /* Light blue color */
              }
            `}
          </style>
          <h1 className="zonec">OEE</h1>
          <h2 className="zonec">Zone C</h2>
          <ApexChartC className="ml-auto" />
        </div>
        <div class="col-sm-12">
          <style>
            {`
              h5 {
                color: #000000 ; /* Light blue color */
              }
            `}
          </style>
          <h2>Availability</h2>

          <ColumnChart className="ml-auto" />
        </div>
        <div class="col-sm-12">
          <DynamicLoadedChart className="ml-auto" />
        </div>
      </Row>
    </div>

  );
};

export default Dashboard;
