import React from 'react';
import ArretImage from './arret.png';
const Arret = ({ width = 48 }) => {
  return (
    <img
      src={ArretImage}
      alt="Arret"
      style={{ width: `${width}%`, height: 'auto' }}
    />
  );
};

export default Arret;
