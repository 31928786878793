import React from 'react';
import productionImage from './production.png';

const Production = ({ width = 50 }) => {
  return (
    <img
      src={productionImage}
      alt="Production"
      style={{ width: `${width}%`, height: 'auto' }}
    />
  );
};

export default Production;

