import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "../../theme";
import Sidebar from "../global/Sidebar";
import Topbar from "../global/Topbar"; // Adjust the path accordingly
import NavBar from "../global/Navbar";
import Dashboard from "../dashboard";
import Supervision from "../supervision/supervision";
import Sourceanalytix from "../energy_management/sourceanalytix";
import CarbonFootprint from "../carbon_footprint/carbon_footprint";
import Analysis from "../analysis/analysis";
import RowMaterial from "../source management/row_material";
import StopMachine from "../source management/stop_machine";
function Home() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <Sidebar isSidebar={isSidebar} />
          <main className="content">
            {/* <Topbar setIsSidebar={setIsSidebar} /> */}
            <NavBar setIsSidebar={setIsSidebar} />
            <Routes>
              <Route path="/dash" element={<Dashboard />} />
              <Route path="/supervision1" element={<Supervision />} />
              <Route path="/analysis" element={<Analysis />} />
              <Route path="/energy_management" element={<Sourceanalytix />} />
              <Route path="/carbon_footprint" element={<CarbonFootprint />} />
              <Route path="/row_material" element={<RowMaterial />} />
              <Route path="/stop_machine" element={<StopMachine />} />
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default Home;