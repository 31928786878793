import React, { useState } from 'react';
import '../../assets/css/authen.css';
//import '../../assets/css/icons.min.css';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'popper.js';
import '../../assets/images/profile-img.png'
import profileImage from '../../assets/images/profile-img.png';

const Auth = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const users = [
    { username: "user1", password: "password1" },
    { username: "user2", password: "password2" },
    // Add more users as needed
  ];
  const navigate = useNavigate();

  const handleLogin = () => {
    const user = users.find(
      (user) => user.username === username && user.password === password
    );
    if (user) {
      // Set the user as logged in
      setIsLoggedIn(true);
      alert("Login successful!");
      navigate('/home/dash');
    } else {
      alert("Invalid username or password");
      navigate('/');
    }

    // Redirect to /dash

  };
  const handleLogout = () => {
    // Log out the user
    setIsLoggedIn(false);
    // Clear the input fields
    setUsername("");
    setPassword("");
  };
  return (
    <>

      <head>
        <meta charSet="utf-8" />
        <title>Login | EcoFactoriz</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta content="Premium Multipurpose Admin & Dashboard Template" name="description" />
        <meta content="Themesbrand" name="author" />
        {/* App favicon */}
        <link rel="shortcut icon" href="../assets/images/favicon.ico" />

        {/* Icons Css */}
        <link href="../assets/css/css_auth/icons.min.css" rel="stylesheet" type="text/css" />
        {/* App Css*/}
        <link href="../assets/css/css_auth/app.min.css" id="app-style" rel="stylesheet" type="text/css" />
      </head>
      {isLoggedIn ? (
        <div>
          <p>Welcome, {username}!</p>
          <button onClick={handleLogout}>Logout</button>
        </div>
      ) : (
        <body>
          <div className="account-pages">
            <div className="container">
              <div className="row justify-content-center m-0">
                <div className="col-md-8 col-lg-6 col-xl-5">
                  <div className="card overflow-hidden">
                    <div className="bg-primary bg-soft">
                      <div className="row">
                        <div className="col-7">
                          <div className="text-primary p-4">
                            <h5 className="text-primary">Welcome To EcoFactoriz</h5>
                            <p>Sign in</p>
                          </div>
                        </div>
                        <div className="col-5 align-self-end">
                          <img src={profileImage} alt="" className="img-fluid" />
                        </div>
                      </div>
                    </div>
                    <div className="card-body pt-0">
                      <div className="auth-logo">
                        {/* <a href="index.html" className="auth-logo-light">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img src="../assets/images/logo-light.svg" alt="" className="rounded-circle" height="34" />
                          </span>
                        </div>
                      </a>

                      <a href="index.html" className="auth-logo-dark">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img src="assets/images/logo.svg" alt="" className="rounded-circle" height="34" />
                          </span>
                        </div>
                      </a> */}
                      </div>
                      <div className="p-2">
                        <form className="form-horizontal" action="#">
                          <div className="mb-3">
                            <label htmlFor="username" className="form-label">Username</label>
                            <input type="text" className="form-control" id="username" placeholder="Enter username" value={username} onChange={(e) => setUsername(e.target.value)} />
                          </div>

                          <div className="mb-3">
                            <label className="form-label">Password</label>
                            <div className="input-group auth-pass-inputgroup">
                              <input type="password" className="form-control" placeholder="Enter password" value={password} onChange={(e) => setPassword(e.target.value)} aria-label="Password" aria-describedby="password-addon" />
                              <button className="btn btn-light" type="button" id="password-addon">
                                <i className="mdi mdi-eye-outline"></i>
                              </button>
                            </div>
                          </div>

                          <div className="form-check">
                            <input className="form-check-input" type="checkbox" id="remember-check" />
                            <label className="form-check-label" htmlFor="remember-check">
                              Remember me
                            </label>
                          </div>

                          <div className="mt-3 d-grid">
                            <button className="btn btn-primary waves-effect waves-light"
                              type="submit"
                              onClick={handleLogin}>Log In</button>
                          </div>
                          {/* 
                        <div className="mt-4 text-center">
                          <h5 className="font-size-14 mb-3">Sign in with</h5>

                          <ul className="list-inline">
                            <li className="list-inline-item">
                              <a href="javascript::void()" className="social-list-item bg-primary text-white border-primary">
                                <i className="mdi mdi-facebook"></i>
                              </a>
                            </li>
                            <li className="list-inline-item">
                              <a href="javascript::void()" className="social-list-item bg-info text-white border-info">
                                <i className="mdi mdi-twitter"></i>
                              </a>
                            </li>
                            <li className="list-inline-item">
                              <a href="javascript::void()" className="social-list-item bg-danger text-white border-danger">
                                <i className="mdi mdi-google"></i>
                              </a>
                            </li>
                          </ul>
                        </div> */}

                          <div className="mt-4 text-center">
                            <a href="auth-recoverpw.html" className="text-muted"><i className="mdi mdi-lock me-1"></i> Forgot your password?</a>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 text-center">

                    <div>
                      <p>Don't have an account ? <a href="#" className="fw-medium text-primary"> Signup now </a> </p>
                      <p className='m-0'>© <script>document.write(new Date().getFullYear())</script> EcoFactoriz 2023.  <i className="mdi mdi-heart text-danger"></i> </p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          {/* end account-pages */}

          {/* JAVASCRIPT */}
          <script src="assets/libs/jquery/jquery.min.js"></script>
          <script src="assets/libs/bootstrap/js/bootstrap.bundle.min.js"></script>
          <script src="assets/libs/metismenu/metisMenu.min.js"></script>
          <script src="assets/libs/simplebar/simplebar.min.js"></script>
          <script src="assets/libs/node-waves/waves.min.js"></script>

          {/* App js */}
          <script src="assets/js/app.js"></script>
        </body>
      )}
    </>
  );
}

export default Auth;