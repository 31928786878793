import React from 'react';
import TotalCarbonEmissions from '../../components/PiechChartTotalCarbonEmissions';
import TotalCarbonEmissionsA from '../../components/PiechChartTotalCarbonEmissionsA';
import TotalCarbonEmissionsB from '../../components/PiechChartTotalCarbonEmissionsB';
import TotalCarbonEmissionsC from '../../components/PiechChartTotalCarbonEmissionsC';
import { Row} from "react-bootstrap";
import Header from "../../components/Header";
const ParentComponent = () => {
  return (
    <div className="container-fluid p-20" >
         <Row className="justify-content-center">
        <Header title="Carboon FootPrint Of Factory" />
      </Row>
      <Row className="mt-4 pr-12 pl-12">
    <div className="col-md-4 ">
    </div> 
    <div className="col-md-4 ">
        < TotalCarbonEmissions/>
    </div> 
    <div className="col-md-4 ">
        </div> 
    </Row>
    <Row className="mt-4 pr-12 pl-12">
    <div className="col-md-4 ">
        < TotalCarbonEmissionsA/>
    </div> 
    <div className="col-md-4 ">
        < TotalCarbonEmissionsB/>
    </div> 
    <div className="col-md-4 ">
        < TotalCarbonEmissionsC/>
    </div> 
    </Row>
    <Row>
  {/* <footer style={{ fontSize: '20px' }}>
    <p>Note:  Total's Unit is (Tons of CO2 e) </p>
  </footer> */}
</Row>

  
    </div>
    
  );
};

export default ParentComponent;