import { Box, useTheme, Typography } from "@mui/material";
import { tokens } from "../../theme";
import React from "react";
import Production from "../../components/Production";
import Arret from "../../components/Arret";
import Demarrage from "../../components/Demarrage";
const Supervision = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const data = ["A", "P", "A", "P", "D", "P", "A", "P", "D", "P", "D", "A"];

    const getComponentByType = (type) => {
      switch (type) {
        case "P":
          return <Production />;
        case "A":
          return <Arret />;
        case "D":
          return <Demarrage />;
        default:
          return null;}};
    return(
        <Box m="20px">
        <Box
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridAutoRows="120px"
          gap="30px"
          style={{ border: '2px solid black', borderRadius: '8px', padding: '10px' , background: '#E5E7E9'}}
        >
          <Box
            gridColumn="span 4"
            display="flex"
            alignItems="center"
            justifyContent="center"
            style={{ border: '2px solid black', borderRadius: '8px', padding: '10px' , background:'#ffe6e6'}}
          >
            <Production width={30} />
            <Typography variant="h4" fontWeight="600" color={colors.grey[100]}>
             In Production
            </Typography>
           </Box>
           <Box
             gridColumn="span 4"
            display="flex"
            alignItems="center"
            justifyContent="center"
            style={{ border: '2px solid black', borderRadius: '8px', padding: '10px', background:'#ffe6e6' }}
            >
            <Arret width={28} />
            <Typography variant="h4" fontWeight="600" color={colors.grey[100]}>
              Stopped
            </Typography>
            </Box>
           <Box
            gridColumn="span 4"
            display="flex"
            alignItems="center"
            justifyContent="center"
            style={{ border: '2px solid black', borderRadius: '8px', padding: '10px' , background:'#ffe6e6'}}
            >
            <Demarrage width={30} />
            <Typography variant="h4" fontWeight="600" color={colors.grey[100]}>
            In Startup
            </Typography>
            </Box>
  
          {data.map((type, index) => (
            <Box
              key={index}
              gridColumn="span 3"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              {getComponentByType(type)}
              <Typography variant="h4" fontWeight="600" color={colors.grey[100]}>
                M{index + 1}
              </Typography>
            </Box>
          ))}
           <Typography variant="h4" fontWeight="600" color={colors.grey[100]}>
                                                              Zone A
            </Typography>
        </Box>
      </Box>
    );
  };

    export default Supervision;
    