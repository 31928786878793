import React from 'react';
import ReactApexChart from 'react-apexcharts';

const DynamicLoadedChart = () => {
  const convertToPercentage = (value, total) => ((value / total) * 100).toFixed(2);

  const seriesData = [400, 430, 448, 470, 540, 580, 690, 1100, 1200, 1380];
  const total = seriesData.reduce((sum, value) => sum + value, 0);

  const series = [{
    data: seriesData.map(value => convertToPercentage(value, total))
  }];

  const options = {
    chart: {
      type: 'bar',
      height: 380
    },
    plotOptions: {
      bar: {
        barHeight: '100%',
        distributed: true,
        horizontal: true,
        dataLabels: {
          position: 'bottom'
        },
      }
    },
    colors: ['#33b2df', '#546E7A', '#d4526e', '#13d8aa', '#A5978B', '#2b908f', '#f9a3a4', '#90ee7e',
      '#f48024', '#69d2e7'
    ],
    dataLabels: {
      enabled: true,
      textAnchor: 'start',
      style: {
        colors: ['#fff']
      },
      formatter: function (val, opt) {
        return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val + '%';
      },
      offsetX: 0,
      dropShadow: {
        enabled: true
      }
    },
    stroke: {
      width: 1,
      colors: ['#fff']
    },
    xaxis: {
      categories: ['MATERIAL QUALITY ISSUES', 'MECHANICAL FAILURE', 'SETUP ', 'ELECTRICAL FAILURE', 'MATERIAL SHORTAGE', 'NO PODUCTION PLANNED', 'BREAK',
        'BAD MAINTENANCE', 'SHFT HANDOVER', 'UNCOMMMENTED'
      ],
    },
    yaxis: {
      labels: {
        show: false
      }
    },
    title: {
      text: 'Downtime Percentage',
      align: 'center',
      floating: true
    },
    subtitle: {
      text: '',
      align: 'center',
    },
    tooltip: {
      theme: 'dark',
      x: {
        show: false
      },
      y: {
        title: {
          formatter: function () {
            return ''
          }
        }
      }
    }
  };

  return (
    <div id="chart">
      <ReactApexChart options={options} series={series} type="bar" height={380} />
    </div>
  );
};

export default DynamicLoadedChart;


