import React, { Component } from 'react';
import RTChart from 'react-rt-chart';
import '../assets/css/RealTimeGraph.css'; // Import your external CSS file

class RealTimeGraph extends Component {
  componentDidMount() {
    // Set up interval to force update every second
    this.interval = setInterval(() => this.forceUpdate(), 1000);
  }

  componentWillUnmount() {
    // Clear the interval when the component is unmounted
    clearInterval(this.interval);
  }

  getRandomValue() {
    // Replace this with your logic to get random values
    return Math.floor(Math.random() * 100);
  }

  render() {
    // Generate random values for Voltage and Current
    const data = {
      date: new Date(),
      Voltage: this.getRandomValue(),
      Current: this.getRandomValue(),
    };
   

    return (
      <div className="whiteBackground">    
      <RTChart
        fields={['Voltage', 'Current']}
        data={data}
        className="my-rt-chart" // Add a custom class for styling
      />
      </div>
    );
  }
}

export default RealTimeGraph;