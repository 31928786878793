import React, { useState, useRef, useEffect } from 'react';
import { DayPilot, DayPilotScheduler } from "daypilot-pro-react";
import Zoom from "./Zoom";
import '../../assets/css/scrolltable.css';

const Scheduler = () => {
  const [config, setConfig] = useState({

    startDate: "2023-11-01",
    days: 31,
    scale: "Day",
    timeHeaders: [
      { groupBy: "Month" },
      { groupBy: "Day", format: "d" }
    ],
    cellWidthSpec: "Auto",
    cellWidth: 50,
    durationBarVisible: false,
    treeEnabled: true,
    rowHeaderColumns: [
      { name: "Machine" },
      { name: " Max Speed", display: "speed", width: 50 },
      { name: "Type", display: "type", width: 50 },
      { name: "Zone Location", display: "zone", width: 90 },
    ],
    onEventMoved: args => {
      console.log("Event moved: ", args.e.data.id, args.newStart, args.newEnd, args.newResource);
      getScheduler().message("Event moved: " + args.e.data.text);
    },
    onEventResized: args => {
      console.log("Event resized: ", args.e.data.id, args.newStart, args.newEnd);
      getScheduler().message("Event resized: " + args.e.data.text);
    },
    onTimeRangeSelected: args => {
      DayPilot.Modal.prompt("New event name", "Event").then(modal => {
        getScheduler().clearSelection();
        if (!modal.result) {
          return;
        }
        getScheduler().events.add({
          id: DayPilot.guid(),
          text: modal.result,
          start: args.start,
          end: args.end,
          resource: args.resource
        });
      });
    },
    onBeforeEventRender: args => {
      if (!args.data.backColor) {
        args.data.backColor = "#93c47d";
      }
      args.data.borderColor = "darker";
      args.data.fontColor = "white";

      args.data.areas = [];
      if (args.data.locked) {
        args.data.areas.push(
          {
            right: 4,
            top: 8,
            height: 18,
            width: 18,
            symbol: "icons/daypilot.svg#padlock",
            fontColor: "white"
          }
        );
      } else if (args.data.plus) {
        args.data.areas.push(
          {
            right: 4,
            top: 8,
            height: 18,
            width: 18,
            symbol: "icons/daypilot.svg#plus-4",
            fontColor: "white"
          }
        );
      }
    },
  });

  const schedulerRef = useRef();

  const getScheduler = () => schedulerRef.current.control;

  const zoomChange = (args) => {
    switch (args.level) {
      case "month":
        setConfig({
          ...config,
          startDate: DayPilot.Date.today().firstDayOfMonth(),
          days: DayPilot.Date.today().daysInMonth(),
          scale: "Day"
        });
        break;
      case "week":
        setConfig({
          ...config,
          startDate: DayPilot.Date.today().firstDayOfWeek(),
          days: 7,
          scale: "Day"
        });
        break;
      default:
        throw new Error("Invalid zoom level");
    }
  };

  const cellWidthChange = (ev) => {
    const checked = ev.target.checked;
    setConfig(prevConfig => ({
      ...prevConfig,
      cellWidthSpec: checked ? "Auto" : "Fixed"
    }));
  };

  const loadData = (args) => {
    const resources = [
      {
        name: "Injection Molding Machine", id: "G2", expanded: true, children: [
          { name: " M 1", speed: 1050, type: 80, zone: "Zone A", id: "A" },
          { name: " M 2", speed: 1050, type: 80, zone: "Zone A", id: "B" },
          { name: " M 3", speed: 1050, type: 150, zone: "Zone A", id: "C" },
          { name: " M 4", speed: 1050, type: 150, zone: "Zone A", id: "D" },
          { name: " M 5", speed: 850, type: 80, zone: "Zone A", id: "E" },
          { name: " M 6", speed: 850, type: 80, zone: "Zone A", id: "F" },
          { name: " M 7", speed: 1050, type: 150, zone: "Zone A", id: "G" },
          { name: " M 8", speed: 1050, type: 150, zone: "Zone A", id: "H" },
          { name: " M 9", speed: 850, type: 80, zone: "Zone A", id: "I" },
          { name: " M 10", speed: 1050, type: 80, zone: "Zone A", id: "J" },
          { name: " M 11", speed: 1050, type: 200, zone: "Zone B", id: "K" },
          { name: " M 12", speed: 650, type: 150, zone: "Zone B", id: "L" },
          { name: " M 13", speed: 850, type: 200, zone: "Zone C", id: "M" },
          { name: " M 14", speed: 850, type: 250, zone: "Zone C", id: "N" },
          { name: " M 15", speed: 650, type: 250, zone: "Zone C", id: "O" },
          { name: " M 16", speed: 1050, type: 300, zone: "Zone C", id: "P" },
          { name: " M 17", speed: 650, type: 80, zone: "Zone C", id: "A" },
          { name: " M 18", speed: 650, type: 80, zone: "Zone C", id: "B" },
          { name: " M 19", speed: 850, type: 150, zone: "Zone C", id: "C" },
          { name: " M 20", speed: 650, type: 350, zone: "Zone D", id: "D" },
          { name: " M 21", speed: 850, type: 450, zone: "Zone D", id: "E" },
          { name: " M 22", speed: 650, type: 300, zone: "Zone D", id: "F" },
          { name: " M 23", speed: 650, type: 350, zone: "Zone D", id: "G" },
          { name: " M 24", speed: 650, type: 250, zone: "Zone D", id: "H" },
          { name: " M 25", speed: 850, type: 450, zone: "Zone E", id: "I" },
          { name: " M 26", speed: 1050, type: 200, zone: "Zone E", id: "J" },
          { name: " M 27", speed: 1050, type: 150, zone: "Zone E", id: "K" },
          { name: " M 28", speed: 1050, type: 150, zone: "Zone F", id: "L" },
          { name: " M 29", speed: 850, type: 80, zone: "Zone F", id: "M" },
          { name: " M 30", speed: 850, type: 300, zone: "Zone F", id: "N" },
          { name: " M 31", speed: 650, type: 300, zone: "Zone I", id: "O" },
          { name: " M 32", speed: 650, type: 250, zone: "Zone I", id: "P" },
        ]
      },
      {
        name: "Extruder Machine", id: "G1", expanded: true, children: [
          { name: " M 33", speed: 850, type: 300, zone: "Zone I", id: "Q" },
          { name: " M 34", speed: 650, type: 300, zone: "Zone I", id: "R" },
          { name: " M 35", speed: 650, type: 250, zone: "Zone I", id: "S" },
        ]
      },
    ];

    const events = [
      { id: 101, text: "Maintenance 101", start: "2023-11-02T00:00:00", end: "2023-11-05T00:00:00", resource: "A" },
      { id: 102, text: "Maintenance 102", start: "2023-11-06T00:00:00", end: "2023-11-10T00:00:00", resource: "A" },

      {
        id: 103,
        text: "Reservation 103",
        start: "2023-11-03T00:00:00",
        end: "2023-11-10T00:00:00",
        resource: "C",
        backColor: "#6fa8dc",
        locked: true
      },
      {
        id: 104,
        text: "Reservation 104",
        start: "2023-11-02T00:00:00",
        end: "2023-11-08T00:00:00",
        resource: "E",
        backColor: "#f6b26b",
        plus: true
      },
      {
        id: 105,
        text: "Reservation 105",
        start: "2023-11-03T00:00:00",
        end: "2023-11-09T00:00:00",
        resource: "G",
      },
      {
        id: 106,
        text: "Reservation 106",
        start: "2023-11-02T00:00:00",
        end: "2023-11-07T00:00:00",
        resource: "B",
      }
    ];

    getScheduler().update({
      resources,
      events
    });
  }


  useEffect(() => {
    // const myDiv = document.getElementByClass("scheduler_default_corner");
    // myDiv.innerHTML = "";
    loadData();
  }, []);

  return (
    <div >
      <div className="toolbar" style={{ paddingLeft: "60px" }}>
        <Zoom onChange={args => zoomChange(args)} />

        <span className="toolbar-item"><label><input type="checkbox" checked={config.cellWidthSpec === "Auto"}
          onChange={ev => cellWidthChange(ev)} /> Auto width</label></span>
      </div>
      <DayPilotScheduler
        {...config}
        ref={schedulerRef}
      />
    </div>
  );
}

export default Scheduler;
